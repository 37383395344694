import React from 'react';

export const formatTitleForURL = (title) => {
    return title
        .toString()
        .toLowerCase()
        .trim()
        .replace(/[^a-z0-9]+/g, '-')  // Replace all non-alphanumeric characters with hyphens
        .replace(/^-|-$/g, '');        // Remove leading and trailing hyphens
};
