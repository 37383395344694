import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import API_URL from '../../config/config';
import Icons from '../../components/icons';

const Category = () => {
    const { category } = useParams();
    const [news, setNews] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [loadingSkeleton, setLoadingSkeleton] = useState(true);

    const fetchNews = async (offset) => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_URL}get-category.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    category,
                    offset,
                    limit: 9,
                }),
            });
            const data = await response.json();

            if (data.error === 0) {
                setNews((prevNews) => {
                    const existingNewsIds = new Set(prevNews.map(item => item.id));
                    const newNews = data.news.filter(item => !existingNewsIds.has(item.id));
                    return [...prevNews, ...newNews];
                });
                setHasMore(data.news.length === 9);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            setError('An error occurred while fetching news.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (category) {
            setNews([]);
            setOffset(0);
            setLoadingSkeleton(true); // Show skeleton on category change
            fetchNews(0);
            
            const timeoutId = setTimeout(() => {
                setLoadingSkeleton(false);
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [category]);

    // Handle scroll event to trigger loading more news
    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.scrollHeight - 100) {
                if (hasMore && !isLoading) {
                    setOffset(prevOffset => prevOffset + 9);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [hasMore, isLoading]);

    useEffect(() => {
        if (offset > 0) {
            fetchNews(offset);
        }
    }, [offset]);

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    return (
        <>
            <div className='container-lg'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/">Home</Link>
                        </li>
                        <li><span><Icons.SlashSvg /></span></li>
                        <li className="breadcrumb-item active" aria-current="page">
                            {capitalizeFirstLetter(category)}
                        </li>
                    </ol>
                </nav>
            </div>
            <div className='category-section padding-50'>
                <div className='container-lg'>
                    <div className='flex-box justify-center'>
                        <div className='custom-heading text-center'>
                            <span>{capitalizeFirstLetter(category)}</span>
                        </div>
                    </div>

                    {isLoading && <div className="skeleton-loader"></div>} {/* Skeleton Loader for initial load */}
                    {error && <div className='error-message'>{error}</div>}

                    <div className='main-fashion padding-50'>
                        <div className='row-gap'>
                            <div className="news-messsanory">
                                {/* Main Right Section: First News */}
                                {news.length > 0 && (
                                    <div className="right-section">
                                        {loadingSkeleton ? (
                                            <div className="skeletant-design sh-651"></div> // Skeleton for the first news
                                        ) : (
                                            <div className="nms-left">
                                            <Link to={`/detail/${news[0].url}`} className='nm-inner'>
                                                <img
                                                    src={news[0].image}
                                                    alt={news[0].title}
                                                    className="main-image"
                                                />
                                                <div className='nm-content'>
                                                    <div className='overlay'></div>
                                                    <div className="main-content">
                                                        <h3>{news[0].sort_title}</h3>
                                                        <h2>{news[0].title}</h2>
                                                        <h3>By Forbes News</h3>
                                                    </div>
                                                </div>
                                            </Link>
                                            </div>
                                        )}
                                    </div>
                                )}

                                {/* Left Section: Remaining News */}
                                {news.length > 1 && (
                                    <div className="left-section">
                                        <div className="grid-container">
                                            {news.slice(1).map((item) => (
                                                <Link to={`/detail/${item.url}`} key={item.id} className="card">
                                                    {loadingSkeleton ? (
                                                        <div className="skeletant-design sh-413"></div> // Skeleton for other news items
                                                    ) : (
                                                        <>
                                                            <img src={item.image} alt={item.title} className="card-image grid-item" />
                                                            <div className="card-content">
                                                            {/* <div className="skeletant-design sh-20"></div> */}
                                                                <h4>{item.sort_title}</h4>
                                                               {/* <div className="skeletant-design sh-30"></div>  */}
                                                                <h3>{item.title}</h3>
                                                            </div>
                                                        </>
                                                    )}
                                                </Link>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    
                    {/* Show skeleton for more news if `isLoading` is true */}
                    {isLoading && (
                        <div className="skeleton-loader-more">
                            {/* This can be a skeleton loader for the subsequent news */}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Category;
