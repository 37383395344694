import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import * as Img from '../../components/Img'; // Import your images
import API_URL from '../../config/config'; // Adjust path to your API config

const Coverstories = () => {
    const { category } = useParams(); // Get category from URL
    const [news, setNews] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // Show loader initially

    // Fetch trending news
    const fetchTrendingNews = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_URL}get-coverstory-top.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({}), // No body needed based on your API
            });
            const data = await response.json();

            if (data.error === 0) {
                setNews(data.trending_news);
            } else {
                setError(data.msg || 'Failed to fetch trending news.');
            }
        } catch (error) {
            setError('An error occurred while fetching trending news.');
        } finally {
            // Stop showing the skeleton loader after 10 seconds
            setTimeout(() => {
                setIsLoading(false);
            }, 3000);
        }
    };

    useEffect(() => {
        fetchTrendingNews();
    }, []);

    return (
        <div className="coverstories padding-50">
            <div className="flex-box">
                <div className="custom-heading">Coverstories</div>
            </div>

            <div className="main-artical pt-4">
                <div className="container-lg row-gap">
                    <div className="article-grid">
                        {isLoading ? (
                            // Skeleton loader
                            Array(4)
                                .fill(0)
                                .map((_, index) => (
                                    <div
                                        className="skeletant-design article-card sh-386"
                                        key={index}
                                    >
                                        {/* Placeholder content */}
                                        <div className="skeletant-image"></div>
                                        <div className="skeletant-content">
                                            <div className="skeletant-title"></div>
                                            <div className="skeletant-subtitle"></div>
                                        </div>
                                    </div>
                                ))
                        ) : news.length === 0 ? (
                            <div className="d-none">No news available.</div>
                        ) : (
                            news.map((newsItem) => (
                                <Link to={`/detail/${newsItem.url}`} key={newsItem.id}>
                                    <div className="article-card">
                                        <div className="image-container">
                                            <img
                                                src={newsItem.image || Img.Market4}
                                                alt={newsItem.title}
                                                style={{ width: '100%' }}
                                            />
                                            <div className="overlaysummary">
                                                <div className="overlay-effect"></div>
                                                <div className="overlay-text"></div>
                                            </div>
                                        </div>
                                        <div className="article-content">
                                            <h4 className="category">{newsItem.sort_title}</h4>
                                            <h3 className="title">{newsItem.title}</h3>
                                        </div>
                                    </div>
                                </Link>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Coverstories;
