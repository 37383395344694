import React from 'react'
import Commoninformation from '../Pagehome/Commoninformation'
import { Link } from 'react-router-dom'
import Icons from '../../components/icons';

const Aboutus = () => {
    return (
        <>
            {/* <Commoninformation /> */}
            <div className='container-lg'>
                {/*breadcrunb start*/}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/">Home</Link>
                        </li>
                        <li><span><Icons.SlashSvg /></span></li>
                        <li className="breadcrumb-item active" aria-current="page">
                            About Us
                        </li>
                    </ol>
                </nav>
                {/*breadcrunb End*/}
            </div>
            <div className='innerpages-section padding-50'>
                <div className='container-lg'>

                    <div className='flex-box justify-center mb-4'>
                        <div className='custom-heading  text-center'>
                            About Us
                        </div>
                    </div>

                    <div className='inp-inner'>
                        <div class="custom-heading ch-medium">Our Story</div>
                        <div className='custom-text ct-small'>The Forbes stories no one else is willing to tell. What makes us different is quite simply our FOMO (for the uninitiated non-millennial, that’s our news-hungry fear of missing out). Millennials are defined by a hunger to know and have it all, and that's what The Forbes strives to appease through its unbiased looking glass. In the process, it constantly questions status quo, sparking relevant conversations along the way.</div>
                        <div className='custom-text ct-small'>Our team is a collective of newshounds, maverick story tellers, editors, producers, designers, analysts and reporters who have cut their teeth on the best news teams in the country. We make sense of the systematic chaos that is the world today and seek to bring you the story, however you want it – on your phone through videos, op-eds, infographics, animations and documentaries.</div>

                        <div class="custom-heading ch-medium">How Are We Funded?</div>
                        <div className='custom-text ct-small'>The Forbes person tells stories no one else is willing to tell. What makes us different is quite simply our FOMO (for the uninitiated non-millennial, that’s our news-hungry fear of missing out). Millennials are defined by a hunger to know and have it all, and that's what The Forbes person strives to appease through its unbiased looking glass. In the process, it constantly questions status quo, sparking relevant conversations along the way.</div>
                        <div className='custom-text ct-small'>Our team is a collective of newshounds, maverick story tellers, editors, producers, designers, analysts and reporters who have cut their teeth on the best news teams in the country. We make sense of the systematic chaos that is the world today and seek to bring you the story, however you want it – on your phone through videos, op-eds, infographics, animations and documentaries.</div>

                        <div class="custom-heading ch-medium">Got an Idea?</div>
                        <div className='custom-text ct-small'>We want to take news out of the newsroom. Help us Unscrew the World. Seen or heard something that you think makes for a good story? We'd love to tell it. You can pitch your story ideas, photos and submissions at editor@forbes.com. Someone in our team, particularly versed in that subject, will get back to you pronto!</div>

                        <div class="custom-heading ch-medium">We Go Beyond Banners</div>
                        <div className='custom-text ct-small'>Drop a mail at sales@forbes.com and we will get back to you soon</div>

                        <div class="custom-heading ch-medium">Join Us</div>
                        <div className='custom-text ct-small'>If you’ve got a bit of heart in you and are willing to take on stories that demand you dig deep, long and hard for the truth, then The Forbes is the newsroom for you.</div>
                        <div className='custom-text ct-small'>Did we mention that we have a range of roles that would suit anybody with a penchant for telling stories? Think traditional newsrooms don’t have room for your doodle, your animator, your ball-point, your phone camera? At The Forbes, we ask candidates simply to find – and share – their voices in the digital medium they’re best acquainted with. Between our English and Hindi websites, we have a bevy of journalists, camerapersons, VT editors, crappy-copy killers, design romancers, social media-oholics and product evangelists. You might fit right in!</div>

                        <div class="custom-heading ch-medium">Our Story</div>
                        <div className='custom-text ct-small'>The Forbes person tells stories no one else is willing to tell. What makes us different is quite simply our FOMO (for the uninitiated non-millennial, that’s our news-hungry fear of missing out). Millennials are defined by a hunger to know and have it all, and that's what The Forbes person strives to appease through its unbiased looking glass. In the process, it constantly questions status quo, sparking relevant conversations along the way.</div>
                        <div className='custom-text ct-small'>Our team is a collective of newshounds, maverick story tellers, editors, producers, designers, analysts and reporters who have cut their teeth on the best news teams in the country. We make sense of the systematic chaos that is the world today and seek to bring you the story, however you want it – on your phone through videos, op-eds, infographics, animations and documentaries.</div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Aboutus