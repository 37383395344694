import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import * as Img from '../../components/Img';
import Icons from '../../components/icons';
import API_URL from "../../config/config";
import Commoninformation from '../Pagehome/Commoninformation';
import { formatTitleForURL } from '../../function';
import Article from '../Pagehome/Article';

// Import images
import articalone from '../../img/news/articalone.webp';
import articaltwo from '../../img/news/articaltwo.png';
import articalthree from '../../img/news/articalthree.webp';
import articalfour from '../../img/news/articalfour.webp';

const articles = [
    {
        imgSrc: articalone,
        category: "Weddings",
        title:
            "From a Sabyasachi sari to a Dior mini dress, this bride wore all-white couture at her Swedish-Indian wedding",
        author: "Hasina Jeelani",
        date: "20 October 2024",
    },
    {
        imgSrc: articaltwo,
        category: "Weddings",
        title: "Sorry lovers, public proposals are officially cringe",
        author: "Darshita Goyal",
        date: "16 October 2024",
    },
    {
        imgSrc: articalthree,
        category: "Sponsored",
        title:
            "Only Falguni Shane Peacock can make prêt look this good—and it's all in New Delhi",
        author: "Vanshika Jain",
        date: "10 October 2024",
    },
    {
        imgSrc: articalfour,
        category: "Weddings",
        title:
            "This bride wore an ivory pearl lehenga to her wedding on a remote ocean sandbar in the Maldives",
        author: "Elise Taylor",
        date: "8 October 2024",
    },
];

const Detail = () => {
    const { title } = useParams();
    const { url } = useParams();
    const [newsItem, setNewsItem] = useState(null);
    const [recentNews, setRecentNews] = useState([]);
    const [previewNews, setPreviewNews] = useState([]);
    const [nextNews, setNextNews] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [categories, setCategories] = useState([]);
    const [isPreviewDropdownOpen, setIsPreviewDropdownOpen] = useState(false);
    const [isNextDropdownOpen, setIsNextDropdownOpen] = useState(false);
    const [news, setNews] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [ads, setAds] = useState([]);
    const [loadingSkeleton, setLoadingSkeleton] = useState(true);
    const [offset, setOffset] = useState(0);

    const fetchNewsDetails = async (currentOffset = 0) => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_URL}get-news-detail.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ url, offset: currentOffset }),
            });
            const data = await response.json();

            if (data.error === 0) {
                setNewsItem(data.news);
                setRecentNews(data.recent_news);
                setPreviewNews(data.previous_news);
                setNextNews(data.next_news);
            } else {
                setError(data.msg || 'Failed to fetch news details.');
            }
        } catch (error) {
            setError('An error occurred while fetching news details.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchNewsDetails();
    }, [url]);

    

    useEffect(() => {
        if (url) {
            setNews([]);
            setOffset(0);
            setLoadingSkeleton(true); // Show skeleton on category change
            fetchNewsDetails(0);
            
            const timeoutId = setTimeout(() => {
                setLoadingSkeleton(false);
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [url]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch(`${API_URL}get-category-title.php`); // Update with your API endpoint
                const data = await response.json();
                if (data.error === 0) {
                    setCategories(data.categories);
                } else {
                    setError(data.msg || 'Failed to fetch categories.');
                }
            } catch (error) {
                setError('An error occurred while fetching categories.');   
            }
        };

        fetchCategories();
    }, []);


    const togglePreviewDropdown = () => setIsPreviewDropdownOpen(!isPreviewDropdownOpen);
    const toggleNextDropdown = () => setIsNextDropdownOpen(!isNextDropdownOpen);

    const currentUrl = window.location.href;
    const fbShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`;
    const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}&text=${encodeURIComponent(newsItem?.title || '')}`;
    const telegramShareUrl = `https://t.me/share/url?url=${encodeURIComponent(currentUrl)}&text=${encodeURIComponent(newsItem?.title || '')}`;
    const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(newsItem?.title || '')} - ${encodeURIComponent(currentUrl)}`;
    const LinkdinShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(currentUrl)}`;
    const MailShareUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=prajapati.kalpesh92@gmail.com&su=${encodeURIComponent(newsItem?.title || '')}&body=${encodeURIComponent(currentUrl)}`;
   

    const renderSkeleton = () => (
        <div className='skeleton-loader'>
            <div className="skeletant-news-img sh-500" />
            <div className="skeletant-news-title sh-70" />
            <div className="skeletant-news-text sh-50" />
            <div className="skeletant-news-date sh-20" />
        </div>
    );

    const fetchTrendingNews = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_URL}get-coverstory-bottom.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({}), // No body needed based on your API
            });
            const data = await response.json();

            if (data.error === 0) {
                setNews(data.trending_news);
                setHasMore(data.trending_news.length > 0); // If there are any trending news items
            } else {
                setHasMore(false);
                setError(data.msg || 'Failed to fetch trending news.');
            }
        } catch (error) {
            setError('An error occurred while fetching trending news.');
        } finally {
            setIsLoading(false);
        }
    };

    // Fetch ads
    useEffect(() => {
        const fetchAds = async () => {
            try {
                const response = await fetch(`${API_URL}get-coverstory-top.php`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ height: 600, width: 300 }) // Sending dimensions
                });
                const data = await response.json();

                if (data.error === 0) {
                    setAds(data.ads);
                } else {
                    setError(data.msg || 'Failed to fetch advertisements.');
                }
            } catch (error) {
                setError('An error occurred while fetching advertisements.');
            }
        };

        fetchAds();
    }, []);

    // Set up interval to change ads every 10 seconds


    useEffect(() => {
        fetchTrendingNews();
    }, []);


    const CopyLink = ({ CopyUrl }) => {
        const handleCopy = (e) => {
          e.preventDefault(); // Prevent navigation
          navigator.clipboard.writeText(CopyUrl).then(() => {
            alert('URL copied to clipboard!');
          }).catch((err) => {
            console.error('Failed to copy: ', err);
          });
        };
    };    

    return (
        <>
            {/* <Commoninformation /> */}
            <div className='container-lg'>
                <nav aria-label="breadcrumb">
                {loadingSkeleton ? (
                                            <div className="skeletant-design"></div> // Skeleton for the first news
                                        ) : (
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/">Home</Link>
                        </li>
                        <li><span><Icons.SlashSvg /></span></li>
                        <li className="breadcrumb-item">
                            <Link to="/">Category</Link>
                        </li>
                        <li><span><Icons.SlashSvg /></span></li>
                        {isLoading ? (
                            renderSkeleton()
                        ) : (
                            newsItem && (
                                <>
                                    <li className="breadcrumb-item active" aria-current="page">{newsItem.title}</li>
                                </>
                            )
                        )}

                    </ol>
                                        )}
                </nav>
            </div>
            <div className='detail-page padding-50'>
                <div className='container-lg'>
                    <div className='main-detail-section'>
                        {isLoading ? (
                            renderSkeleton()
                        ) : (
                            newsItem && (
                                <>  
                                    <div className='mds-left-inner'>
                                    {loadingSkeleton ? (
                                            <div className="skeletant-design sh-651"></div> // Skeleton for the first news
                                        ) : (
                                        <img src={newsItem.image || Img.Plsnews} alt={newsItem.title} />
                                    )}
                                    </div>
                                    <div className='mds-right-inner main-artical'>

                                        <div class="article-content">
                                            {/* <div className="skeletant-design sh-105"></div> */}
                                            {/* <h4 class="category"></h4> */}
                                            {loadingSkeleton ? (
                                            <div className="skeletant-design sh-171"></div> // Skeleton for the first news
                                        ) : (
                                            <h3 class="title">{newsItem.title}</h3>
                                        )}
                                        {loadingSkeleton ? (
                                            <div className="skeletant-design sh-60 mt-2"></div> // Skeleton for the first news
                                        ) : (
                                            <p class="author">By Forbes News<br /></p>
                                        )}
                                            
                                            <div className='social-shearing'>
                                            {loadingSkeleton ? (
                                            <div className="skeletant-design sh-60 mt-2"></div> // Skeleton for the first news
                                        ) : (
                                                <ul>
                                                    <li>
                                                        <a href={fbShareUrl} target="_blank" rel="noopener noreferrer" className='fb-link'>
                                                            <img src={Img.Fb} alt='facebook' />
                                                            {/* <div className="icon-tag">Facebook Share</div> */}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={twitterShareUrl} target="_blank" rel="noopener noreferrer" className='tw-link'>
                                                            <img src={Img.tw} alt='twitter' />
                                                            {/* <div className="icon-tag">Twitter Share</div> */}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={LinkdinShareUrl} target="_blank" rel="noopener noreferrer" className='tw-link'>
                                                            <img src={Img.Linkdin} alt='linkdin' />
                                                            {/* <div className="icon-tag">Linkdin Share</div> */}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="/#" rel="noopener noreferrer" className='tw-link' onClick={(e) => {
                                                            e.preventDefault();
                                                            navigator.clipboard.writeText(currentUrl)
                                                                .then(() => alert('Link copied to clipboard!'))
                                                                .catch(err => console.error('Failed to copy the URL:', err));
                                                        }}>
                                                            <img src={Img.Link} alt='link' />
                                                            {/* <div className="icon-tag">Link Share</div> */}
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a href={telegramShareUrl} target="_blank" rel="noopener noreferrer" className="tl-link">
                                                            <img src={Img.Tel} alt='telegram' />
                                                            {/* <div className="icon-tag">Telegram Share</div> */}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={MailShareUrl} target="_blank" rel="noopener noreferrer" className="tl-link">
                                                            <img src={Img.Mail} alt='mail' />
                                                            {/* <div className="icon-tag">Email Share</div> */}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={whatsappShareUrl} target="_blank" rel="noopener noreferrer" className="wh-link">
                                                            <img src={Img.whatsapp} alt='whatsapp' />
                                                            {/* <div className="icon-tag">whatsapp Share</div> */}
                                                        </a>
                                                    </li>
                                                </ul>
                                                )}
                                            </div>

                                            {/* <div className='share-btn'>
                                                <Link to='/' className='share'><span><Icons.ShareSvg /></span>Share</Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </>
                            )
                        )}
                    </div>

                    {/* <div className='devider'></div> */}

                    <div className='row row-gap-4 my-5'>
                        <div className='col-md-7 col-lg-7 col-xl-7'>
                           
                        {loadingSkeleton ? (
                                            <div className="skeletant-design"></div> // Skeleton for the first news
                                        ) : (
                            <div className='news-info pb-3'>
                            {/* <div className="skeletant-design sh-105"></div> */}
                                {/* <div className='byauthors'>
                                    By <span><Link to='/'>politics News</Link></span>
                                </div> */}
                                <div className='news-timestamp'>
                                    <div className='media-timestamp'>
                                        <span>November 15, 2024 at 7:30 AM GMT+5:30</span>
                                    </div>
                                    <div className='update-timestamp'>
                                        <i>Updated on <span>November 15, 2024 at 1:03 PM GMT+5:30</span></i>
                                    </div>
                                </div>

                            </div>
                                        )}

                            {/* <div className="skeletant-design sh-105"></div>        */}
                            {loadingSkeleton ? (
                                            <div className="skeletant-design"></div> // Skeleton for the first news
                                        ) : (
                            <div className='main-tagbookmark'>
                                <div className='mtb-inner'>
                                    <Link to='/'><span><Icons.BookmarkSvg /></span>Save</Link>
                                    <Link to='/'><span><Icons.LanguageSvg /></span>Translate</Link>
                                    <Link to='/'><span> <img src={Img.Learning} alt='whatsapp' /></span>Listen <span className='time-d'>(6:07)</span> </Link>
                                    <Link to='/'><span><Icons.GiftSvg /></span>Gift This Article</Link>
                                </div>
                            </div>  
                                        )}

                            {isLoading ? (
                                renderSkeleton()
                            ) : (
                                newsItem && (
                                    <>
                                        <div class="article-content">
                                        {loadingSkeleton ? (
                                            <div className="skeletant-design sh-215 mb-2"></div> // Skeleton for the first news
                                        ) : (
                                            <p dangerouslySetInnerHTML={{ __html: newsItem.description }}></p>
                                        )}
                                        </div>
                                    </>
                                )
                            )}


                            <div className='dp-inner'>
                                {/* {isLoading ? (
                                    renderSkeleton()
                                ) : (
                                    newsItem && (
                                        <>
                                            <img src={newsItem.image || Img.Plsnews} alt={newsItem.title} />
                                            <div className='custom-heading'>{newsItem.title}</div>
                                            <div className='custom-text ct-small' dangerouslySetInnerHTML={{ __html: newsItem.description }} />
                                        </>
                                    )
                                )} */}

                                {/* <div className='social-media-post'>
                                    <div className='smp-left'>
                                        <img src={Img.Socialone} alt='social' />
                                        <p className='mt-2'>Instagram.com/@styleby</p>
                                    </div>
                                    <div className='smp-right'>
                                        <img src={Img.Socialtwo} alt='social' />
                                        <p className='mt-2'>Instagram.com/@styleby</p>
                                    </div>
                                </div> */}

                                {/* Preview and Next */}
                                {loadingSkeleton ? (
                                            <div className="skeletant-design sh-95"></div> // Skeleton for the first news
                                        ) : (
                                <div className='detail-next-preview'>
                                    {previewNews.length > 0 && (
                                        <div className='detail-preview' onClick={togglePreviewDropdown}>
                                            <div className='dnp-title'>
                                                <span><Icons.ChevronleftSvg /></span> Previous
                                                <div className="custom-text ct-small">{previewNews[0]?.title}</div>
                                            </div>
                                            <div className={`dropdown-menu ${isPreviewDropdownOpen ? 'open' : ''}`}>
                                                <ul>
                                                    {previewNews.map(news => (
                                                        <li key={news.id}>
                                                            <Link to={`/detail/${(news.url)}`}>{news.title}</Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    )}

                                    {nextNews.length > 0 && (
                                        <div className='detail-next text-end' onClick={toggleNextDropdown}>
                                            <div className='dnp-title text-end'>
                                                Next <span><Icons.Chevronright /></span>
                                                <div className="custom-text ct-small">{nextNews[0]?.title}</div>
                                            </div>
                                            <div className={`dropdown-menu ${isNextDropdownOpen ? 'open' : ''}`}>
                                                <ul>
                                                    {nextNews.map(news => (
                                                        <li key={news.id}>
                                                            <Link to={`/detail/${(news.url)}`}>{news.title}</Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                        )}
                            </div>
                        </div>

                        <div className='col-md-5 col-lg-5 offset-xl-1 col-xl-4'>
                            <div className='common-news-box row-gap detail-news-box'>
                            {loadingSkeleton ? (
                                            <div className="skeletant-design"></div> // Skeleton for the first news
                                        ) : (
                                <div className='flex-box'>
                                
                                    <div className='custom-heading ch-medium'>Recent Posts</div>
                                        
                                </div>
                                )}
                                {isLoading ? (
                                    Array.from({ length: 5 }).map((_, index) => (
                                        <div key={index} className='cnb-box'>
                                            <div className='cnb-inner'>
                                                <div className='cnb-img'>
                                                    <div className='skeletant-news-img sh-125' />
                                                </div>
                                                <div className='cnb-content'>
                                                    <div className='custom-heading ch-medium'>
                                                        <div className='skeletant-news-title sh-50' />
                                                    </div>
                                                    <div className='custom-text'>
                                                        <div className='skeletant-news-text sh-40' />
                                                    </div>
                                                    <div className='custom-text'>
                                                        <div className='skeletant-news-date sh-20' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    recentNews.map(news => (
                                        <div className='cnb-box' key={news.id}>
                                            <Link to={`/detail/${(news.url)}`}>
                                                <div className='cnb-inner'>
                                                    <div className='cnb-img'>
                                                    {loadingSkeleton ? (
                                            <div className="skeletant-design sh-125"></div> // Skeleton for the first news
                                        ) : (
                                                        <img src={news.image_name ? `${news.image}` : Img.P1} alt={news.title} />
                                        )}
                                                    </div>
                                                    {loadingSkeleton ? (
                                            <div className=" cnb-content skeletant-design sh-125"></div> // Skeleton for the first news
                                        ) : (
                                                    <div className=" cnb-content">
                                                       
                                                        <div className='custom-heading ch-medium'>{news.title}</div>
                                                       
                                                        <div className='custom-text'>{news.sort_title}</div>

                                                    </div>
                                        )}
                                                </div>
                                            </Link>
                                        </div>
                                    ))
                                )}

                                {/* Quick Links Section */}
                                {/* <div className="quick-links-section">
                                    <div className="quick-links-inner">
                                        <div className="custom-heading ch-medium">Quick Links</div>

                                        <div className="quick-links-buttons">
                                            {categories.map((category) => (
                                                <Link className="quick-link-btn common-btn-item cbi-outline" key={category.id} to={`/category/${formatTitleForURL(category.title)}`}>
                                                    <div className="quick-link-button">
                                                        {category.title}
                                                    </div>
                                                </Link>
                                            ))}
                                        </div>
                                    </div>
                                </div> */}
                            </div>


                        </div>
                    </div>
                </div>
            </div>

            {/*more story*/}
            <div className='main-artical padding-50'>
                <div className='container-lg row-gap'>
                    {/* <div className='flex-box'> */}
                        {/* <div className='custom-heading ch-medium'>
                            More Coverstories
                        </div> */}
                        {/* <Link className='custom-heading ch-small' to="/category/politics" >
                        View all <span className='icon'><Icons.ArrowlongrightSvg /></span>
                    </Link> */}
                    {/* </div> */}
                    {/* <div className="article-grid">
                        {news.length === 0 ? (
                            <div className='d-none'>No news available.</div>
                        ) : (
                            news.map(newsItem => (
                                <Link to={`/detail/${(newsItem.url)}`} >
                                    <div className="article-card" key={newsItem.id}>
                                        <div className="image-container">
                                            <img src={newsItem.image || Img.Market4} alt={newsItem.title} style={{ width: '100%' }} />

                                            <div className="overlaysummary"><div className="overlay-effect"></div><div className="overlay-text">Forbes</div></div>
                                        </div>
                                        <div className="article-content">

                                            <h4 className="category">{newsItem.sort_title}</h4>

                                            <h3 className="title">{newsItem.title}</h3>

                                        </div>
                                    </div>
                                </Link>
                            ))
                        )}

                    </div> */}
                </div>
            </div>

        </>
    );
};

export default Detail;
