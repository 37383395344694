import React, { useState, useEffect } from 'react';
// Icons & images common files
import * as Img from '../../components/Img';
import Icons from '../../components/icons';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';

const Commoninformation = () => {
    const [isDarkMode, setIsDarkMode] = useState(false);

    // Effect to add/remove dark mode class on body
    useEffect(() => {
        if (isDarkMode) {
            document.body.classList.add('dark-mode');
        } else {
            document.body.classList.remove('dark-mode');
        }
    }, [isDarkMode]);

    // Toggle the dark mode
    const toggleTheme = () => {
        setIsDarkMode(!isDarkMode);
    };

    // Date picker
    const [selectedDate, setSelectedDate] = useState(new Date()); // Initialize with today's date

    const formatDate = (date) => {
        if (!date) return '';
        return format(date, "do MMMM, yyyy");
    };

    // Custom input for DatePicker
    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        // <div className="custom-date-input" onClick={onClick} ref={ref}>
        //     {value || "28th June, 2024"} {/* Default value if none selected */}
        // </div>
        <div className="custom-date-input">
             {value || "28th June, 2024"} {/* Default value if none selected */}
         </div>
    ));

    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = (index) => {
        setActiveIndex(index);
    };
    

    return (
        <div className='common-infromation'>
            <div className='cni-inner'>
                <div className='cni-left'>
                    {/* <div className='cni-datetime'>
                        <span><img src={Img.Cloud} alt="Weather" /></span>25° USA, HP
                    </div> */}
                </div>
                <div className='cni-right'>
                    <div className='cni-detail'>
                        <div className='cni-datetime'>
                            <span><Icons.CalendarSvg /></span>
                            <DatePicker
                                selected={selectedDate}
                                onChange={(date) => setSelectedDate(date)}
                                dateFormat="do MMMM, yyyy"
                                placeholderText="Select a date"
                                customInput={<CustomInput value={formatDate(selectedDate)} />}
                            />
                        </div>

                        {/* <div className='cni-mode'>
                            <div className="theme-toggle" onClick={toggleTheme} style={{ cursor: 'pointer' }}>
                                {isDarkMode ? <Icons.SunSvg /> : <Icons.MoonSvg />}
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Commoninformation;
