import React from 'react'
import Commoninformation from '../Pagehome/Commoninformation'
import { Link } from 'react-router-dom'
import Icons from '../../components/icons';

const Termscondition = () => {
    return (
        <>
            {/* <Commoninformation /> */}
            <div className='container-lg'>
                {/*breadcrunb start*/}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/">Home</Link>
                        </li>
                        <li><span><Icons.SlashSvg /></span></li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Terms & Conditions
                        </li>
                    </ol>
                </nav>
                {/*breadcrunb End*/}
            </div>
            <div className='innerpages-section padding-50'>
                <div className='container-lg'>
                    <div className='flex-box justify-center mb-4'>
                        <div className='custom-heading  text-center '>
                            Terms & Conditions
                        </div>
                    </div>

                    <div className='inp-inner'>
                        {/* <div class="custom-heading ch-medium">Terms & Conditions</div> */}
                        <div className='custom-text ct-small'>Welcome, and thank you for your interest in the Forbes News website (the "Site") and its related services that enable users to access exclusive content, updates, and other features (collectively with the Site, the "Service"). Forbes News is pleased to offer you access to the Service under the terms and conditions set forth below. The following terms, including our Guidelines (as defined below), and any future modifications (collectively, these "Terms") form an agreement between you and Forbes News.</div>
                        <div class="custom-heading ch-medium">PLEASE READ THIS AGREEMENT CAREFULLY.</div>
                        <div className='custom-text ct-small'>BY ACCESSING, BROWSING, OR USING THE SERVICE, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT AGREE, PLEASE DO NOT USE THE SERVICE.</div>
                        <p></p>
                        <div class="custom-heading ch-medium">1. Eligibility and Access</div>
                        <ol className='custom-orderlist-style'>
                            <li>
                                <div class="custom-heading ch-small">1.1 COPPA Notice</div>
                                <div className='custom-text ct-small'>The Service is not available to persons under the age of 17 or to any users suspended or removed from the Service by Forbes News. By using the Service, you represent that you are at least 17 years old. If you are 17 or older but under 18, you must review these Terms with your parent or guardian to ensure mutual understanding.</div>
                            </li>
                        </ol>

                        <div class="custom-heading ch-medium">2. Content Ownership and Use</div>
                        <ol className='custom-orderlist-style'>
                            <li>
                                <div class="custom-heading ch-small">2.1 Content Rights</div>
                                <div className='custom-text ct-small'>All content available on the Service is intended for personal, non-commercial use. The content is owned by Forbes News or its licensors and protected by copyright and other intellectual property laws. You are prohibited from copying, distributing, or publishing any content unless expressly permitted.</div>
                                <div class="custom-heading ch-small">2.2 Establishing an Account</div>
                                <div className='custom-text ct-small'>When creating an account, you are solely responsible for maintaining its confidentiality and security. Unauthorized use of your account must be reported immediately to Forbes News.</div>
                            </li>
                        </ol>

                        <div class="custom-heading ch-medium">3. Service Use Requirements</div>
                        <div className='custom-text ct-small'>Using the Service requires compatible devices, internet access, and potential updates. These requirements are your responsibility and may impact Service performance.</div>

                        <div class="custom-heading ch-medium">4. Intellectual Property</div>
                        <div className='custom-text ct-small'>The Service contains proprietary material owned by Forbes News and its licensors. You are granted no rights to this material except as explicitly stated in these Terms.</div>

                        <div class="custom-heading ch-medium"> 5. Account Management </div>
                        <div className='custom-text ct-small'>Forbes News reserves the right to place advertising on your account or Service interface without compensation to you. If representing an organization, you confirm your authority to bind that entity to these Terms.</div>

                        <div class="custom-heading ch-medium">6. Privacy Policy</div>
                        <div className='custom-text ct-small'>Your privacy is important to Forbes News. Please review the Privacy Policy to understand how we collect, use, and disclose your personal information.</div>

                        <div class="custom-heading ch-medium">7. Prohibited Uses</div>
                        <div className='custom-text ct-small'>You agree not to:</div>

                        <ul className='custom-list-style'>
                            <li>Use the Service for unlawful or harmful purposes.</li>
                            <li>Access, alter, or disrupt the Service via unauthorized means.</li>
                            <li>Upload offensive, illegal, or harmful content.</li>
                        </ul>

                        <div class="custom-heading ch-medium">8. User Submissions and Ownership</div>
                        <ol className='custom-orderlist-style'>
                            <li>
                                <div class="custom-heading ch-small">8.1 Ownership of Content</div>
                                <div className='custom-text ct-small'>Users retain full ownership of their submissions. Forbes News does not claim rights to sell or distribute user content without permission.</div>
                                <div class="custom-heading ch-small">8.2 Content Moderation</div>
                                <div className='custom-text ct-small'>Forbes News reserves the right to monitor, edit, or remove content that violates these Terms.</div>
                            </li>
                        </ol>


                        <div class="custom-heading ch-medium">9. Modifications to Terms</div>
                        <div className='custom-text ct-small'>Forbes News reserves the right to amend these Terms at its discretion. Continued use of the Service after changes constitutes acceptance of the revised Terms.</div>

                        <div class="custom-heading ch-medium">10. Governing Law</div>
                        <div className='custom-text ct-small'>These Terms are governed by the laws of [insert governing jurisdiction], without regard to conflict of law principles.</div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Termscondition