import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import * as Img from '../../components/Img'; // Import your images
import API_URL from '../../config/config'; // Adjust path to your API config
import Commoninformation from '../Pagehome/Commoninformation';
import Icons from '../../components/icons';
import { formatTitleForURL } from '../../function';

const Category = () => {
    const { category } = useParams(); // Get category from URL
    const [news, setNews] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [ads, setAds] = useState([]);
    const [isAdVisible, setIsAdVisible] = useState(true);
    const [currentAdIndex, setCurrentAdIndex] = useState(0);

    // Fetch trending news
    const fetchTrendingNews = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_URL}get-trending-news.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({}), // No body needed based on your API
            });
            const data = await response.json();

            if (data.error === 0) {
                setNews(data.trending_news);
                setHasMore(data.trending_news.length > 0); // If there are any trending news items
            } else {
                setHasMore(false);
                setError(data.msg || 'Failed to fetch trending news.');
            }
        } catch (error) {
            setError('An error occurred while fetching trending news.');
        } finally {
            setIsLoading(false);
        }
    };

    // Fetch ads
    useEffect(() => {
        const fetchAds = async () => {
            try {
                const response = await fetch(`${API_URL}get-advertisements.php`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ height: 600, width: 300 }) // Sending dimensions
                });
                const data = await response.json();

                if (data.error === 0) {
                    setAds(data.ads);
                } else {
                    setError(data.msg || 'Failed to fetch advertisements.');
                }
            } catch (error) {
                setError('An error occurred while fetching advertisements.');
            }
        };

        fetchAds();
    }, []);

    // Set up interval to change ads every 10 seconds
    useEffect(() => {
        let adInterval;
        if (ads.length > 0 && isAdVisible) {
            adInterval = setInterval(() => {
                setCurrentAdIndex((prevIndex) => (prevIndex + 1) % ads.length);
            }, 10000);
        }

        return () => clearInterval(adInterval);
    }, [ads, isAdVisible]);

    useEffect(() => {
        fetchTrendingNews();
    }, []);


    const handleAdClose = () => {
        setIsAdVisible(false);
    };

    return (
        <>
            {/* <Commoninformation /> */}
            <div className='container-lg'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/">Home</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                           Trending News
                        </li>
                    </ol>
                </nav>
            </div>
            <div className='category-section padding-50'>
                <div className='container-lg'>
                    <div className='flex-box justify-center padding-bottom-50'>
                        <div className='custom-heading text-center'>
                            Trending news
                        </div>
                    </div>

                    {isLoading && <div>Loading...</div>}
                    {error && <div className='error-message'>{error}</div>}

                    <div className='row'>
                        <div className={`col-${isAdVisible ? 'md-9' : 'md-12'} row-gap`}>
                            <div className='row row-gap-4'>
                                {news.length === 0 ? (
                                    <div className='d-none'>No news available.</div>
                                ) : (
                                    news.map(newsItem => (
                                        <div key={newsItem.id} className='col-md-6'>
                                            <div className='common-news-box'>
                                                <div className='cnb-box'>
                                                    <Link to={`/detail/${formatTitleForURL(newsItem.title)}`}>
                                                        <div className='cnb-inner'>
                                                            <div className='cnb-img'>
                                                                <img
                                                                    src={newsItem.image || Img.Market4}
                                                                    alt={newsItem.title}
                                                                    style={{ width: '100%' }}
                                                                />
                                                            </div>
                                                            <div className='cnb-content'>
                                                                <div className='custom-heading ch-medium'>
                                                                    {newsItem.title}
                                                                </div>
                                                                <div className='custom-text'>
                                                                    {newsItem.sort_title}
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>

                            <div className='common-advertisement margin-25 mb-0 d-none d-md-block'>
                                <button className='close-btn'>
                                    <Icons.XmarkSvg />
                                </button>
                                <Link className='adt-inner'>
                                    <img src={Img.Advertisethree} alt="Advertisement" />
                                </Link>
                            </div>
                        </div>

                        {isAdVisible && ads.length > 0 && (
                            <div className='col-md-3'>
                                <div className='adver-tisement d-none d-md-block'>
                                    <button className='close-btn' onClick={handleAdClose}>
                                        <Icons.XmarkSvg />
                                    </button>
                                    <Link className='adt-inner' to={ads[currentAdIndex].link}>
                                        <img src={ads[currentAdIndex].image} alt="Advertisement" />
                                    </Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Category;
